<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>赞助回报汇总</b></span>
            <div class="flex-center" @click="back">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="project-info">
            <div class="settlement-tab">
                <el-table
                        :data="settlement"
                        header-row-class-name="table-header"
                        style="width: 100%">
                    <el-table-column width="100" label="序号" type="index" align="center">
                    </el-table-column>
                    <el-table-column align="center" prop="corporate_name" width="300px" label="企业名称">
                    </el-table-column>
                    <el-table-column align="center" prop="zan_zhu_hui_bao" label="赞助回报">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "sponsorReward",
        data() {
            return {
                id: "",
                isAdmin: false,
                settlement: [],
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.isAdmin = this.user.mid === Number(this.$route.query.uid)
            this.$emit("setIndex", [1, 1]);
            this.init();

        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        methods: {
            init() {
                this.$api.apiContent
                    .getZanZhuHuiBao({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.settlement = res.data;
                    });
            },
            //  返回
            back() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }
        .project-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;
            min-height: 750px;
            .project-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .title-tab {
                    .active {
                        background: #3b77e7;
                        color: #fff;
                    }
                    .settlement {
                        margin-left: 10px;
                    }
                }

                .el-select {
                    margin-right: 20px;
                }

                .el-input {
                    margin: 0 20px;
                    width: 268px;
                }
            }
            .settlement-tab {
                padding: 30px;
            }
        }
    }
</style>
